<template>
  <div class="blog-page container-fluid row mx-0">
    <iframe src="https://blog.market.live/" frameborder="0"/>
  </div>
</template>

<script>
  import { $stats } from '../../plugins/analytics/vue-analytics-plugin';

  export default {
    name: 'BlogPage',

    computed: {
      blogLink() {
        const items = this.$store.getters['navLinks/links'];
        const item = items.find((item) => item.title === 'Blog');

        if (!item) {
          return null;
        }

        const link = item.link.replace('get_item', 'web');

        return `${document.location.origin}${link}`;
      },
    },
    created() {
      $stats.send('page_opened', {
        page_type: 'blog',
      });
    },
  };
</script>

<style lang="scss" scoped>
.blog-page {
    iframe {
        width: 100%;
        min-height: 100vh;
    }
}
</style>
